import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaSearch } from 'react-icons/fa';
import CustomAccordion from '../components/common/CustomAccordion';
import LayoutNew from '../components/layout-new';
import Seo from '../components/seo';
import HeroSectionFAQs from '../components/sections/hero-section-faqs';
import HeroSectionFAQsMobile from '../components/sections/hero-section-faqs-mobile';
import SectionFAQsMeeting from '../components/sections/section-faqs-meeting';

const PageFAQsHard = () => {
  const data = useStaticQuery(graphql`
    query PageFAQsHardQuery {
      imgHero: file(relativePath: { eq: "faqs/backgrounds/hero-bg-full-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `);

  const [searchTerm, setSearchTerm] = useState('');
  const [matchedIndex, setMatchedIndex] = useState(-1); // Initialize to -1, indicating no match

  const faqs = [
    { title: "What is the difference between a Micro Captive and an 831(b) Plan?", content: "These terms describe the same tool and are used interchangeably. An 831(b) Plan also known as Micro Captive Insurance or simply a Micro Captive, refers to small insurance entity eligible for special tax advantages under Section 831(b) of the U.S. Internal Revenue Code. Such entities are taxed solely on their investment income, rather than on the  premiums collected, up to a specified threshold. This tax structure is particularly advantageous for small to medium-sized enterprises seeking to enchance their risk management strategies with tax-deferred funds." },

    { title: "What are the specific benefits of an 831(b) plan for my business?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },

    { title: "What is the process of setting up and 831(b) Plan? What are the associated costs?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },

    { title: "How does an 831(b) Plan differ from traditional insurance plans?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },

    { title: "What are the tax implications of establishing an 831(b) plan?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },

    { title: "How does the IRS monitor 831(b) Plans?", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." },
    // Add more FAQs here
  ];

  const faqRefs = useRef([]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      const matchedIndex = faqs.findIndex(faq =>
        faq.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setMatchedIndex(matchedIndex);

      if (matchedIndex !== -1 && faqRefs.current[matchedIndex]) {
        faqRefs.current[matchedIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setMatchedIndex(-1);
  };

  const imgHero = getImage(data.imgHero);

  return (
    <LayoutNew>
      <Seo
        title="Frequently Asked Questions"
        description="Browse through our FAQ page to find answers to the most common questions about 831(b) Plans, associated costs, and more."
        image={imgHero}
        noIndex
      />

      <HeroSectionFAQs 
        title="Frequently Asked Questions"
        subTitle="Quick and efficient answers to common questions about our services and processes."
        imgSrc={imgHero}
      />

      <HeroSectionFAQsMobile 
        title="Frequently Asked Questions"
        subTitle="Quick and efficient answers to common questions about our services and processes."
        imgSrc={imgHero}
      />

      <div className="bg-site-white pt-8 pb-16 md:pt-16 md:pb-14">
        <div className="w-11/12 max-w-screen-lg mx-auto mb-8">
          <p className="text-site-gray-darker text-lg md:text-xl text-center tracking-wider">Browse through our FAQ page to find answers to the most common questions about 831(b) Plans, associated costs, and more. Not finding the answers you're looking for? Our dedicated team is always ready to assist you with additional inquiries. <a href="" className='underline hover:text-site-red'>Chat with us here!</a></p>
        </div>
        <div className="w-11/12 max-w-screen-sm flex flex-col items-center justify-center mx-auto">
          <div style={{ marginBottom: '20px', position: 'relative', width: '100%' }}>
            <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }}>
              <FaSearch size={20} />
            </div>
            <input
              type="text"
              placeholder="Looking for something specific?"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchSubmit}
              style={{ padding: '10px 40px 10px 40px', width: '100%', boxSizing: 'border-box', border: '1px solid #3c3c3c', borderRadius: '5px' }}
            />
            {searchTerm && (
              <button onClick={handleClearSearch} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', border: 'none', background: 'none', cursor: 'pointer', padding: '5px' }}>
                <span className='text-site-red' style={{ fontSize: '26px', fontWeight: 'bold' }}>X</span>
              </button>
            )}
          </div>          
        </div>
        {faqs.map((faq, index) => (
          <div key={index} ref={el => faqRefs.current[index] = el}>
            <CustomAccordion number={index + 1} title={faq.title} isOpen={matchedIndex === index}>
              <p className='text-site-gray-darker text-md md:text-lg tracking-wider mt-6'>{faq.content}</p>
            </CustomAccordion>
          </div>
        ))}
      </div>  

      <SectionFAQsMeeting />  
    </LayoutNew>
  );
};

export default PageFAQsHard;