import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SectionFAQsMeeting = () => {
  const data = useStaticQuery(graphql`
    query SectionFAQsMeetingQuery {
      imgBg: file(relativePath: { eq: "faqs/backgrounds/eeting-section-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgBg = getImage(data.imgBg)

  return (
    <>
      <div className="relative" style={{ display: "grid", background: "#333333", minHeight: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "cover",
            opacity: "0.9",
            height: 400,
          }}
          alt="hero background"
          image={imgBg}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-start px-2">
            <div className="w-full md:w-10/12 lg:w-8/12 flex flex-col items-start justify-start">              
              <h2 className="text-site-gray-dark text-3xl md:text-4xl text-center md:text-left font-extrabold tracking-wider mb-6">Want To Speak With Our Team Directly?</h2>

              <p className='text-site-gray-darker text-xl md:text-2xl text-center md:text-left mb-8 tracking-widest'>We are here to support you every step of the way.</p>

              <div className="w-full md:w-6/12">                  
                <button className="w-full bg-site-red hover:bg-site-red-hover text-lg md:text-xl text-white uppercase tracking-wider px-8 py-3">
                  Schedule a Meeting
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SectionFAQsMeeting
