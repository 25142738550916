import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSectionFAQsMobile = ({ imgSrc, title, subTitle }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionFAQsMobileQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className="relative grid md:hidden pt-14 z-20" style={{ background: "#dff2f2", height: "400px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            objectFit: "fill",
            opacity: "0.5",
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          objectPosition="81% 50%"
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 flex flex-col items-start justify-start px-2">
              <div className="w-full flex flex-col justify-center">
                <div className="w-8/12">
                  <h2 className="text-site-gray-dark text-4xl font-extrabold text-left tracking-widest mb-6">
                    {title}
                  </h2>
                </div>

                <p className="text-site-gray-dark text-xl tracking-wider">{subTitle}</p>                
              </div>
            </div>
            :
            null
          }
        </div>
      </div>           
    </>
  )
}

export default HeroSectionFAQsMobile
