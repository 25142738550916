import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeroSectionFAQs = ({ imgSrc, title, subTitle }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionFAQsQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <div className="relative hidden md:grid pt-14 xl:pt-20 z-20" style={{ background: "#dff2f2", minHeight: "400px", height: "900px" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
          }}
          alt="hero background"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          {title
            ?
            <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-start px-2">
              <div className="w-full md:w-10/12 lg:w-6/12 flex flex-col justify-center">
                <h2 className="text-site-gray-dark text-3xl md:text-6xl xl:text-7xl font-extrabold text-left tracking-widest mb-16">
                  {title}
                </h2>
                
                <div className="w-9/12">
                  <p className="text-site-gray-darker text-1xl md:text-2xl xl:text-3xl tracking-wider">{subTitle}</p>                
                </div>
              </div>
            </div>
            :
            null
          }
        </div>
      </div>           
    </>
  )
}

export default HeroSectionFAQs
